//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import LanguageMixin from '@motionelements/core/src/components/mixins/language.mixin.js';
import channelTypesMixin from '@motionelements/core/src/components/mixins/channelTypes.mixin.js';
import studioAiMixin from '@motionelements/core/src/components/mixins/studio-ai.mixin.js';

import MenuLanguage from '@motionelements/core/src/components/modules/common/MenuLanguage.vue';
import MenuCurrency from '@motionelements/core/src/components/modules/common/MenuCurrency.vue';

import {
  faXTwitter,
  faYoutube,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { faSparkle } from '@fortawesome/pro-solid-svg-icons';

export default {
  name: 'the-footer',
  mixins: [channelTypesMixin, LanguageMixin, studioAiMixin],
  components: {
    MenuLanguage,
    MenuCurrency,
    NuxtClientOnly: () => import('./nav/NuxtClientOnly.vue'),
  },
  data() {
    return {
      icons: {
        faXTwitter,
        faFacebookF,
        faYoutube,
        faAngleDown,
        faSparkle,
      },
      form: {
        language: this.currentLanguage,
        currency: this.currentCurrencyCode,
      },
    };
  },
  computed: {
    ...mapGetters({
      appLanguage: 'app/getLanguageByCode',
      userCurrency: 'user/currencyCode',
    }),
    currentLanguageCode: {
      get() {
        return _.get(this.appLanguage(this.$i18n.locale), 'code');
      },
    },
    currentCurrencyCode: {
      get() {
        return this.userCurrency;
      },
    },
    youtubeUrl() {
      switch (this.currentLanguageCode) {
        case 'ja':
          return 'https://www.youtube.com/channel/UCoM7M80fQE6XoVFo4NaiaGg';
        case 'ko':
          return 'https://www.youtube.com/channel/UCX9KBkXg2GreyfSmvQvpc4g';
        case 'zh-hant':
          return 'https://www.youtube.com/channel/UCPucpqnRR-T5VEuehJgwiYg';
        default:
          return 'https://www.youtube.com/c/motionelements';
      }
    },
    twitterUrl() {
      switch (this.currentLanguageCode) {
        case 'ja':
          return 'https://twitter.com/motionelements';
        default:
          return false;
      }
    },
  },
  methods: {
    onChangeCurrency(currencyCode) {
      this.$store.dispatch('user/changeCurrency', currencyCode);
    },
    onChangeLanguageFooter(languageCode) {
      this.onChangeLanguage(languageCode);
    },
  },
};
